import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import 'rc-slider/assets/index.css';

import Layout from "../components/layout"
import Masonry from "../components/masonry"
import WorkMasonryTeal from "../components/workMasonryTeal"
import SEO from "../components/seo"
import { applyFilter } from "../utils/filter"

const FILTER_LANGUAGE = 'node.frontmatter.language'

const BlogIndex = ({ 
  data,
  pageContext,
  location
}) => {
  const [filter] = useState({
    [FILTER_LANGUAGE]: {
      'eq': pageContext.locale
    }
  })

  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  const filteredPosts = posts
    .filter(applyFilter(filter))

  return (
    <Layout
      location={location} 
      title={siteTitle}
      locale={pageContext.locale}
    >
      <SEO
        title="Trabajos"
        keywords={[`Trabajos`, `Joaquín Rodríguez`, `Joaquin Rodriguez`]}
        lang={pageContext.locale}
      />
      <Masonry>
        {
          filteredPosts.map(({ node }) => (
            <WorkMasonryTeal
              key={node.frontmatter.title}
              title={node.frontmatter.title}
              image={node.frontmatter.thumbnail.childImageSharp.fluid}
              onClick={() => { navigate(node.fields.slug) }}
            />
          ))
        }
      </Masonry>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\/work\/"} },
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            language
            thumbnail {
              childImageSharp{
                fluid(maxWidth: 500, quality: 50) {
                  aspectRatio
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
