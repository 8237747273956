
/*****************************************
 * PRIVATE API
 ****************************************/

const _map = (fn, array) => array.map(fn)

/**
 *
 * @param {any} defaultValue
 * @param {String} path ex: `node.frontmatter.date`
 * @param {Object} obj
 */
const _pathOr = (defaultValue = undefined, path, obj) => {
  try {
    return path.split(".").reduce((acc, subPath) => acc[subPath], obj)
  } catch (err) {
    return defaultValue
  }
}

/*****************************************
 * PUBLIC API
 ****************************************/

const curry = fn => (...args) => fn.length === args.length ? fn(...args) : curry(fn.bind(null, ...args))

const identity = value => value

const map = curry(_map)

const pathOr = curry(_pathOr)

const pipe = (...fns) => arg => fns.reduce((arg, fn) => fn(arg), arg)

const unique = array => Array.from(new Set(array))

export {
  curry,
  identity,
  map,
  pathOr,
  pipe,
  unique,
}
