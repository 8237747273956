import React from "react"
import Image from "gatsby-image"
 // eslint-disable-next-line
import { jsx } from '@emotion/core'
import { rhythm } from "../utils/typography"

const WorkMasonryTeal = ({ 
  image, 
  title, 
  onClick
}) => (
  <div
    {...{
      onClick,
      css:{ 
        position: 'relative',
        cursor: 'pointer',
        ':hover > div': {
          opacity: 1
        }
      }
    }}
  >
    <Image
      {...{
        title,
        alt: title,
        fluid: image,
      }}
    />
    <div
      css={{
        opacity: 0,
        transition: 'opacity 0.3s ease',
        position: `absolute`,
        top: 0,
        width: `100%`,
        height: `100%`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        backgroundColor: `rgba(255, 255, 255, 0.85)`,
      }}
    >
      <h2
        css={{
          fontFamily: `Heebo, sans-serif`,
          textTransform: 'uppercase',
          textDecoration: `none`,
          fontSize: rhythm(0.7),
          fontWeight: 300,
        }}
      >{title}</h2>
    </div>
  </div>
)
  
export default WorkMasonryTeal;