import {
  identity,
  pathOr
} from './fnUtils'

/**
 * .normalize() decomposes graphemes into the combination of simple ones.
 * Ex:
 *  é = e + '
 * 
 * The Replace statement removes the special accents characters
 * 
 * @param {String} string 
 */
const normalizeString = string => string
  .toLowerCase()
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, "")


const eq = (value, operating) => value === operating
const ne = (value, operating) => value !== operating
const gt = (value, operating) => value > operating
const gte = (value, operating) => value >= operating
const lt = (value, operating) => value < operating
const lte = (value, operating) => value <= operating
const fn = (value, operating) => operating(value)
/**
 * 
 * @param {String} value 
 * @param {String} operating 
 * @return {Boolean} Returns true if the operating is empty or if 
 * the operating is contained in the value
 */
const incl = (value, operating) => {
  const normalizedValue = normalizeString(value)
  const normalizedOperating = normalizeString(operating)
  return !operating || normalizedValue.includes(normalizedOperating)
}

const binaryOperators = {
  eq,
  ne,
  gt,
  gte,
  lt,
  lte,
  incl,
  fn
}

export const applyFilter = filter => item => 
  Object.entries(filter).every(([path, operations]) =>
    Object.entries(operations).every(([operator, operating]) => 
      (binaryOperators[operator] || identity)(pathOr('', path, item), operating)
    )
  )