import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import useViewportSize, { SIZES } from "../utils/hooks/useViewportSize"

const { XL, L, M, S, XS } = SIZES

const Masonry = ({
  children = [] 
}) => {
  const { allConfigJson } = useStaticQuery(
    graphql`
      query { 
        allConfigJson (
          filter: { id: { eq: "masonry-config" } }
        ) {
          edges {
            node {
              tealGap
              columnsXS
              columnsS
              columnsM
              columnsL
              columnsXL
            } 
          }
        }
      }
    `
  );
  const viewportSize = useViewportSize()
  const configJson = allConfigJson.edges[0].node;
  const config = {
    cols: {
      [XL]: configJson.columnsXL,
      [L]: configJson.columnsL,
      [M]: configJson.columnsM,
      [S]: configJson.columnsS,
      [XS]: configJson.columnsXS,
    }
  }
  const colsNumb = config.cols[viewportSize]
  // Ordering teals in cols
  const cols = []
  children
    .map(child => (<div css={{margin: `${configJson.tealGap}px`}} children={child}/>))
    .forEach((child, index) => {
    const col = index % colsNumb
    cols[col] = cols[col] || []
    cols[col].push(child)
  })

  return (
    <div
      css={{ 
        width: '100%',
        display: 'flex',
      }}
    >
      {
        cols.map((col, index) => (
          <div
            {...{
              key: index,
              css: {
                display: 'flex',
                flexFlow: 'column',
                flex: 1
              }
            }}
          >{col}</div>
        ))
      }
    </div>
  )
}

export default Masonry;